import React from "react"
import { graphql } from "gatsby"
import PagesLayout from "../../layout/pages"
import { PageHeading, HyperLink, TitleText } from "../../utils/"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Testimony from "../testimonials/testimony"

export const data = graphql`
  query mentorshipEditionQuery($id: String) {
    wpMentorshipEdition(id: { eq: $id }) {
      name
      description
      slug
      customFields {
        status
        bannerflyer {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
        }
      }
    }

    allWpPartner(
      filter: {
        mentorshipEditions: { nodes: { elemMatch: { id: { eq: $id } } } }
      }
    ) {
      edges {
        node {
          title
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, formats: WEBP)
                }
              }
            }
          }
        }
      }
    }

    allWpTestimonial(
      filter: {
        mentorshipEditions: { nodes: { elemMatch: { id: { eq: $id } } } }
      }
    ) {
      edges {
        node {
          title
          content
          excerpt
          customFields {
            linkedinProfileUrl
          }

          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, formats: PNG)
                }
              }
            }
          }
        }
      }
    }
  }
`

const MentorshipEdition = ({ data }) => {
  const mentorshipEdition = data.wpMentorshipEdition
  const name = `DD Mentorship ${mentorshipEdition.name}`
  const image = getImage(mentorshipEdition.customFields.bannerflyer?.localFile)

  const show_partners = data.allWpPartner.edges.map(partner => (
    <GatsbyImage
      image={getImage(
        partner.node.featuredImage.node?.localFile.childImageSharp
      )}
      alt={partner.node.title}
      className="block "
    />
  ))

  const show_testimonials = data.allWpTestimonial.edges.map(
    (theTestimonial, index) => {
      const testimonial = theTestimonial.node

      return <Testimony testimonial={testimonial} key={index} />
    }
  )

  return (
    <PagesLayout>
      <PageHeading text={name} />
      <p>{mentorshipEdition.description}</p>

      <GatsbyImage image={image} alt={name} className=" mt-12 mb-8" />

      <section className="mb-36">
        <HyperLink
          url={`/mentorship/${mentorshipEdition.slug}/mentors`}
          text="See the Mentors List"
        ></HyperLink>
      </section>

      <TitleText title="Testimonials" />
      <div className="grid lg:grid-cols-2 gap-16 mx-auto mb-36">
        {show_testimonials}
      </div>

     {/* <TitleText title="Partners" />*/}
      <section className="flex flex-wrap items-center">{show_partners}</section>
    </PagesLayout>
  )
}

export default MentorshipEdition
